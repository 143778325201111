body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 246, 247);
}
:root {
  --color-text: rgb(236, 233, 230);
}

/* Navigation =============================================================== */
nav {
  display: flex;
  flex-direction: column;
  align-items: normal;
}

nav a {
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-decoration: none;
  padding: 0.75rem;
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border-width: 0;
  background-color: rgb(39, 50, 62);
}

nav a:hover {
  background-color: rgb(39, 50, 62);
}

svg {
  width: 2rem;
  height: 2rem;
  color: rgb(236, 233, 230);
}

.navLabel {
  margin-left: 1rem;
  color: rgb(236, 233, 230);
}

.navHeader {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-left: 0.7rem;
  height: 3rem;
}

.navHeader svg {
  color: rgb(85, 99, 116);
}

/* NewPost ================================================================== */

textarea {
  font-family: inherit;
  margin: 0px;
  padding: 0px;
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  height: 28px;
  width: 100%;
  background-color: transparent;
  border-width: 0px;
  resize: none;
  outline: none;
  color: rgb(219, 216, 213);
}

.newPost {
  position: relative;
  margin-top: 1rem;
  padding-left: 2rem;
  padding-left: 2rem;
  border-bottom: solid 1px rgb(39, 50, 62);
}

.newPost-postRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  justify-content: space-between;
  padding-right: 2rem;
}

.submitButton {
  font-weight: 400;
  border-radius: 0;
  font-size: 18px;
  justify-content: space-between;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  border-radius: 99999px;
  height: 38px;
  background-color: rgb(80, 162, 255);
  color: rgb(27, 39, 51);
  cursor: pointer;
}

.submitButton:disabled {
  background-color: rgb(39, 50, 62);
  color: rgba(219, 216, 213, 0.5);
  cursor: default;
}

.topic {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 38px;
  border-radius: 99999px;
  background-color: rgb(39, 50, 62);
  color: rgb(116, 114, 114);
  font-weight: 700;
  font-size: 120%;
}

.topicInput {
  height: 38px;
  border-width: 0px;
  background-color: transparent;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(80, 162, 255);
  margin-left: 0.5rem;
}

.newPostScrim {
  top: -1rem;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: rgba(27, 39, 51, 0.75);
}

/* Posts ==================================================================== */
.postItem {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  border-bottom: solid 1px rgb(39, 50, 62);
  color: rgb(219, 216, 213);
}

.postOwnerRow {
  font-weight: 600;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-left: 0.5rem;
}

.postOwnerRow a {
  color: rgb(219, 216, 213);
  text-decoration: none;
}

.postOwnerRow a:hover {
  text-decoration: underline;
}

.postRow {
  color: rgb(219, 216, 213);
  font-weight: 400;
  margin-left: 0.5rem;
  width: 100%;
  word-break: break-word;
  line-height: 1.3rem;
}

.profileImage {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 100%;
  color: transparent;
  vertical-align: top;
}

.postLayout {
  display: flex;
  flex-direction: row;
}

.gray {
  color: rgb(85, 99, 116);
}

time {
  color: rgb(85, 99, 116);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.postTopic {
  display: inline-block;
  margin-top: 0.5rem;
  background-color: transparent;
  color: rgb(80, 162, 255);
  padding: 0px;
  font-weight: 500;
  margin-left: 0.5rem;
  width: 100%;
  font-size: 1rem;
  text-decoration: none;
}

.postTopic:hover {
  text-decoration: underline;
}

.status {
  color: rgb(85, 99, 116);
  font-size: 0.875rem;
  line-height: 1.3rem;
}
#element::-webkit-scrollbar {
  display: none;
}

#element {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}
